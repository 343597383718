import { SelectableTileListItemData } from './';

const defaultData: SelectableTileListItemData = {
  element: { label: 'Tile label', selected: false, value: 0 },
};

const selectedData: SelectableTileListItemData = {
  ...defaultData,
  element: { ...defaultData.element, selected: true },
};

export const SelectableTileListItemMock = {
  defaultData,
  selectedData,
};
